import { StoplightProject } from '@stoplight/elements-dev-portal';
import '@stoplight/elements/styles.min.css';
import logo from './logo.svg';
import './App.css';
import NavBar from './components/Navigation/Navigation'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
      <NavBar/>
      </Router>
      <StoplightProject projectId="cHJqOjEwMDkzOA" />
    </div>
  );
}

export default App;
